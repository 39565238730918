import {Button, Checkbox, Col, Row, Table} from "antd";
import {useEffect, useMemo, useState} from "react";
import {ColumnsType, ColumnType} from "antd/lib/table";
import {
  CashFlowRowOverdue,
  CashFlowRowResultDto,
  CashFlowRowResultValuesDto
} from "../../../../domain/dto/cash-flow-row-result.dto";
import {Moment} from "moment";
import {useNumberFormatter} from "../../../service/number-formatter.service";
import {CalculatorOutlined, MinusCircleOutlined, PlusCircleOutlined} from "@ant-design/icons";
import Text from "antd/es/typography/Text";
import {useProfileStorage} from "../../../../core/service/profile.storage";

export type ListCashFlowRowReportProps = {
  loading?: boolean,
  data: CashFlowRowResultDto[],
  overdueData: CashFlowRowOverdue[]
  dates: Moment[],
  onCellClick?: (row: CashFlowRowResultDto, date: Moment) => any,
  dateFormat: 'd' | 'm' | 'y',
}

export function ListCashFlowRowReport(props: ListCashFlowRowReportProps) {
  const { loading, dates, data } = props;
  const [columns, setColumns] = useState<ColumnsType<any>>();
  const [formatResult, setFormatResult] = useState(true);

  const { getProfile } = useProfileStorage()
  const { decimalFormatter } = useNumberFormatter();

  function renderTotal(row: CashFlowRowResultDto) {
    const total = row.values
      ?.map(w => w.totalPaid)
      .reduce((prev: number, current: number) => prev + current, 0)
    return decimalFormatter.format(total ?? 0);
  }

  function getDateformat() {
    switch (props.dateFormat) {
      case "d":
        return 'DD/MM';
      case "m":
        return 'MM/YYYY';
      case "y":
        return "YYYY";
      default:
        return '';
    }
  }

  function renderName(value: any, row: CashFlowRowResultDto) {
    let content = `${row.order} - ${row.name}`;
    let icon = <PlusCircleOutlined style={{color: 'green'}} />;
    switch (row.type) {
      case 'D':
        icon = <MinusCircleOutlined style={{color: 'red'}} />;
        break;
      case 'C':
        icon = <CalculatorOutlined style={{color: "blue"}} />
        break;
      default: break;
    }

    return (
      <Text>
        {icon} {content}
      </Text>
    )
  }

  function onCellClick(row: CashFlowRowResultDto, date: Moment) {
    props.onCellClick && props.onCellClick(row, date);
  }

  function compareValueWithDate(
    val: CashFlowRowResultValuesDto,
    date: Moment
  ): boolean {
    switch (props.dateFormat) {
      case "d":
        return (
          val.year == date.year()
          && val.month == date.month() + 1
          && val.day == date.date()
        );
      case "m":
        return (
          val.year == date.year()
          && val.month == date.month() + 1
        );
      case "y":
        return val.year === date.year();
      default:
        return false;
    }
  }

  function renderRow(id: number, row: CashFlowRowResultDto, date: Moment) {
    let content = null;
    if (row.values?.length) {
      const value = row.values?.find(w => compareValueWithDate(w,date));
      if (value) {
        content = decimalFormatter.format(value.totalPaid);
      }
    }
    content = content ?? `0,00`;
    return (
      <div style={{display: 'block', textAlign: 'right'}}>
        <Button
          onClick={() => row.isResultRow ? null : onCellClick(row, date)}
          type={'text'}
          size={'small'}
        >
          {content}
        </Button>
      </div>
    )
  }

  function renderOverdue(value: any, row: any) {
    const overdueRow = overdueData.find(w => w.id === row.id)
    if (overdueRow) {
      return decimalFormatter.format(
        formatResult ? (row?.overdueAmount ?? 0) / 1000 : overdueRow.amount
      )
    }
    return `0.0`
  }

  function configColumns() {
    const tempColumns = new Map<string, ColumnType<any>>();
    tempColumns.set('name', {
      title: '',
      fixed: 'left',
      dataIndex: 'name',
      width: 200,
      ellipsis: true,
      key: 'name',
      render: renderName
    })
    if (getProfile()?.alias !== 'partner_report') {
      tempColumns.set('overdue', {
        title: 'Aberto',
        fixed: 'left',
        dataIndex: 'overdueAmount',
        ellipsis: true,
        key: 'name',
        // render: renderOverdue
      })
    }
    for(const date of dates) {
      const dateFormatted = date.local(true).format(getDateformat());
      tempColumns.set(dateFormatted, {
        title: dateFormatted,
        key: dateFormatted,
        dataIndex: 'id',
        align: 'center',
        render: (id: any, row: any) => renderRow(id, row, date)
      })
    }
    tempColumns.set('total', {
      title: 'Total',
      key: 'total',
      dataIndex: 'id',
      align: 'right',
      fixed: 'right',
      render: (id: any, row: any) => renderTotal(row)
    })

    setColumns(Array.from(tempColumns.values()));
  }

  useEffect(() => {
    configColumns()
  }, [dates, props.dateFormat])

  function parseValue(value: CashFlowRowResultValuesDto): CashFlowRowResultValuesDto {
    const { totalPaid, totalAmount } = value;
    return {
      ...value,
      totalPaid: formatResult ? totalPaid / 1000 : totalPaid,
      totalAmount: formatResult ? totalAmount / 1000 : totalAmount,
    }
  }

  function formatValue(amount?: number) {
    if (amount) {
      return decimalFormatter.format(formatResult ? amount / 1000 : amount)
    }
    return '0,00'
  }
  const overdueData = useMemo(() => props.overdueData, [props, props.overdueData])
  const parsedDate = useMemo(() => data.map(item => ({
    ...item,
    key: item.id,
    overdueAmount: formatValue(props.overdueData.find(w => w.id === item.id)?.amount),
    values: item.values?.map(parseValue),
    children: item.children?.map(child => ({
      ...child,
      key: child.id,
      overdueAmount: formatValue(props.overdueData.find(w => w.id === child.id)?.amount),
      values: child.values?.map(parseValue)
    }))
  })), [data, props, props.overdueData, formatResult])

  return (
    <>
      <Row>
        <Col>
          <Checkbox
            checked={formatResult}
            onChange={e => setFormatResult(e.target.checked)}
          /> Valor / 1000
        </Col>
      </Row>
      <Table
        size={'middle'}
        bordered
        loading={loading}
        scroll={{x: true}}
        pagination={false}
        columns={columns}
        dataSource={parsedDate}
      />
    </>
  )
}
