import {Button, Dropdown, Form, Input, Menu, message, Modal, Popconfirm} from "antd";
import {DeleteOutlined, FilterOutlined, SaveOutlined} from "@ant-design/icons";
import {useEffect, useMemo, useState} from "react";
import {ReportFilterEntity} from "../../../domain/entity/report-filter.entity";
import {useReportFilterRepository} from "../../../domain/repository/report-filter.repository";
import {FormActionsButtons} from "../../../core/component/form-actions-buttons";

export type FilterManagerProps = {
  entity: string,
  onSelected: (values: any) => any,
  getRawData: () => any
}
export function FilterManager(props: FilterManagerProps) {
  const [form] = Form.useForm()
  const [modalVisible, setModalVisible] = useState(false)
  const [filters, setFilters] = useState<ReportFilterEntity[]>([])

  const reportFilterRepo = useReportFilterRepository()

  async function loadFilters() {
    return reportFilterRepo
      .listFiltersByEntity(props.entity)
      .then(res => setFilters(res.data))
  }

  useEffect(() => {
    if (!reportFilterRepo) {
      return
    }
    loadFilters()
  }, [props.entity])

  const RemoveIcon = (props: { filterId: number }) => (
    <Popconfirm
      title={'Deseja Remover Esse Filtro ?'}
      onConfirm={() => reportFilterRepo.remove(props.filterId.toString()).then((res) => {
        message.success(res.message)
        setModalVisible(false)
        loadFilters()
      })}
    >
      <DeleteOutlined />
    </Popconfirm>
  )

  const menus = useMemo(() => (
    <Menu>
      {filters.map(item => (
        <Menu.Item
          key={item.id}
          icon={<RemoveIcon filterId={item.id} />}
          onClick={() => props.onSelected(item.rawData)}
        >
          {item.name}
        </Menu.Item>
      ))}
      <Menu.Divider key={-1} />
      <Menu.Item key={0} icon={<SaveOutlined />} onClick={() => setModalVisible(true)}>
        Salvar Filtro
      </Menu.Item>
    </Menu>
  ), [filters])

  function onSave(values: any) {
    const args: any = {
      name: values.name,
      rawData: props.getRawData(),
      entity: props.entity
    }
    return reportFilterRepo.create(args)
      .then((res) => {
        message.success(res.message)
        setModalVisible(false)
        return loadFilters()
      })
  }

  return (
    <>
      <Dropdown overlay={menus} >
        <Button type={'default'} icon={<FilterOutlined />} htmlType={'button'} shape={'round'} />
      </Dropdown>
      <Modal visible={modalVisible} footer={null} onCancel={() => setModalVisible(false)}>
        <Form form={form} onFinish={onSave} layout={'vertical'}>
          <Form.Item name={'name'} label={'Nome do Filtro'}>
            <Input />
          </Form.Item>
          <FormActionsButtons />
        </Form>
      </Modal>
    </>
  )
}
