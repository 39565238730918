import {FinancialEntryInstallmentEntity} from "../../../domain/entity/financial-entry-installment.entity";
import {Alert, Form, message} from "antd";
import {DatePickerPtBr} from "../../../core/component/date-picker-ptbr";
import TextArea from "antd/es/input/TextArea";
import {FormActionsButtons} from "../../../core/component/form-actions-buttons";
import {useFinancialEntryRepository} from "../../../domain/repository/financial-entry.repository";
import {useEffect} from "react";
import moment from "moment";

export type FormCancelInstallmentComponentProps = {
  installment: FinancialEntryInstallmentEntity,
  onFinish: () => any,
  onCancel: () => any,
}
export function FormCancelInstallmentComponent(props: FormCancelInstallmentComponentProps) {
  const [form] = Form.useForm();
  const { installment } = props;

  const { revertWriteOffInstallment } = useFinancialEntryRepository();

  useEffect(() => {
    const dtWriteOff = installment.writeOffDate
      ? moment(installment.writeOffDate)
      : null;
    form.setFields([
      { name: 'dateTimeRevertPayment', value: dtWriteOff  }
    ])
  }, [])

  if (!installment.paid) {
    return (
      <Alert
        message={'A Parcela não está baixada'}
        type={'warning'}
        description={'Para cancelar a baixa, é necessário que a parcela esteja baixada'}
        showIcon
      />
    )
  }

  function onFinish(values: any) {
    revertWriteOffInstallment(
      installment.id,
      values,
    ).then(data => {
      message.success(data.message);
      props.onFinish();
    }).catch(err => {
      message.error(err.message);
    })
  }

  return (
    <Form
      form={form}
      layout={'vertical'}
      onFinish={onFinish}
    >
      <Form.Item
        label={'Motivo do Cancelamento da Baixa'}
        name={'reason'}
        rules={[{ required: true }]}
      >
        <TextArea />
      </Form.Item>
      <DatePickerPtBr
        label={'Data Para Extorno'}
        name={'dateTimeRevertPayment'}
        required
      />
      <FormActionsButtons
        onCancel={props.onCancel}
      />
    </Form>
  )
}