import {ModuleRenderProps} from "../../../../core/component/module/module.root";
import {HeaderCashFlow} from "../component/header.cash-flow";
import {Button, Col, Divider, Drawer, Form, Radio, Row, Select, Space} from "antd";
import {Content} from "antd/es/layout/layout";
import {useEffect, useMemo, useRef, useState} from "react";
import {CashFlowRowOverdue, CashFlowRowResultDto} from "../../../../domain/dto/cash-flow-row-result.dto";
import {useCashFlowRowRepository} from "../../../../domain/repository/cash-flow-row.repository";
import moment, {Moment} from "moment";
import {DatePickerPtBr} from "../../../../core/component/date-picker-ptbr";
import {ExceptionOutlined, SearchOutlined} from "@ant-design/icons";
import {CashFlowRowAuditDto} from "../../../../domain/dto/cash-flow-row-audit.dto";
import {CashFlowAuditDateComponent} from "../component/cash-flow-audit-date.component";
import {FirmEntity} from "../../../../domain/entity/firm.entity";
import {useFirmRepository} from "../../../../domain/repository/firm.repository";
import {CostCenterEntity} from "../../../../domain/entity/cost-center.entity";
import {getSorter} from "../../../../core/util/sort-array.util";
import {ListCashFlowRowReport} from "../component/list-cash-flow-row.report";
import {BankAccountEntity} from "../../../../domain/entity/bank-account.entity";
import {useBankAccountRepository} from "../../../../domain/repository/bank-account.repository";
import {FilterManager} from "../../../component/filter-manager";
import {useCostCenterRepository} from "../../../../domain/repository/account.repository";

export type AuditData = {
  data: CashFlowRowAuditDto[],
  row: CashFlowRowResultDto,
  dateTime: Date,
  costCenterIds?: number[],
  firmId?: number,
  bankAccountId?: number,
}

export function ReportCashFlow(props: ModuleRenderProps) {
  const [form] = Form.useForm();
  const [firms, setFirms] = useState<FirmEntity[]>([]);
  const [bankAccounts, setBankAccounts] = useState<BankAccountEntity[]>([]);
  const [costCenters, setCostCenters] = useState<CostCenterEntity[]>([]);
  const [data, setData] = useState<CashFlowRowResultDto[]>([]);
  const [auditData, setAuditData] = useState<AuditData>();
  const [overdueRows, setOverdueRows] = useState<CashFlowRowOverdue[]>([])
  const [dateFormat, setDateFormat] = useState<'d' | 'm' | 'y'>('d');
  const [dates, setDates] = useState<Moment[]>([]);
  const [datePickerFormat, setDatePickerFormat] = useState<'date' | 'month' | 'year'>('date')
  const radioFormatDateRef = useRef<any>()

  const costCenterRepo = useCostCenterRepository();
  const bankAccountRepo = useBankAccountRepository();
  const firmRepo = useFirmRepository();
  const cashFlowRepo = useCashFlowRowRepository();


  useEffect(() => {
    const startDate = moment(new Date()).add(-15, 'days');
    form.setFields([
      { name: 'startDate', value: startDate },
      { name: 'dateFormat', value: dateFormat }
    ])
    bankAccountRepo.findAll().then(res => setBankAccounts(res.data));
    firmRepo.findAll().then(res => setFirms(res.data));
    costCenterRepo.getEnabled().then(res => setCostCenters(res.data))
    cashFlowRepo.getOverdueCashFlowRows().then(res => setOverdueRows(res.data))
    search(startDate, dateFormat);
  }, []);


  function generateDates(startDate: Moment, endDate: Moment, dateFormat: 'd' | 'm' | 'y') {
    const _dates: Moment[] = [];
    let dateRef = moment(startDate).clone();
    let cont = 0;
    const parserFormat = dateFormat === 'd' ? 'day' : (dateFormat === 'm' ? 'month' : 'year');
    while (endDate.diff(dateRef, dateFormat) > 0) {
      _dates.push(moment(dateRef));
      dateRef = moment(dateRef).add(1, parserFormat);
      if (++cont > 15) break;
    }
    setDates(_dates)
  }

  function search(
    startDate: Moment,
    dateFormat: 'd' | 'm' | 'y',
    firmId?: number,
    costCenterIds?: number[],
    bankAccountId?: number,
    download?: boolean
  ) {
    let endDate = moment(startDate).add(15, "days");
    if (dateFormat === 'm') {
      endDate = moment(startDate).add(12, 'month')
    } else if (dateFormat == 'y') {
      endDate = moment(startDate).add(12, 'year');
    }
    if (download) {
      return cashFlowRepo
        .exportReport({
          dateFormat,
          startDate: startDate.toISOString(),
          endDate: endDate.toISOString(),
          firmId,
          costCenterIds,
          bankAccountId
        });
    }
    cashFlowRepo
      .generateReport({
        startDate: startDate.toISOString(),
        endDate: endDate.toISOString(),
        firmId,
        costCenterIds,
        dateFormat,
        bankAccountId
      }).then(res => {
        generateDates(startDate, endDate, dateFormat)
        setData(res.data)
    });
  }

  function auditRow(cashFlowRow: CashFlowRowResultDto, date: Moment) {
    const { costCentersCode, firmId,  bankAccountId } = form.getFieldsValue();
    const costCenterIds: number[] = [];
    for (const costCenter of costCentersCode ?? []) {
      costCenterIds.push(...filterCostCenters(costCenter))
    }
    cashFlowRepo.auditReport({
      cashFlowRowId: cashFlowRow.id,
      dateTime: date.toISOString() ,
      costCenterIds,
      firmId,
      bankAccountId,
      dateFormat
    }).then(res => setAuditData({
      data: res.data,
      row: cashFlowRow,
      dateTime: date.toDate(),
      costCenterIds,
      firmId,
      bankAccountId,
    }));
  }

  function filterCostCenters(code: string): number[] {
    const result = costCenters.filter(w => w.code.startsWith(code))
    if (result && result.length) {
      return result?.map(w => w.id as number)
    }
    return [];
  }

  function onSearch(values: any) {
    const { startDate, firmId, download, dateFormat, bankAccountId } = values;
    const costCentersId: number[] = [];
    for (const costCenter of values.costCentersCode ?? []) {
      costCentersId.push(...filterCostCenters(costCenter))
    }
    setDateFormat(dateFormat);
    return search(startDate, dateFormat, firmId, costCentersId, bankAccountId, download);
  }

  function onExport() {
    return onSearch({
      ...form.getFieldsValue(),
      download: true,
    })
  }

  function getFirmsOpts() {
    return firms.map(item => ({
      label: item.name,
      value: item.id,
    }))
  }

  function getCostCenterOpts() {
    return costCenters
      .sort(getSorter<CostCenterEntity>('code'))
      .map(item => ({
      label: `${item.code} - ${item.name}`,
      value: item.code,
    }))
  }

  function getBankAccountsOpts() {
    return bankAccounts
      .sort(getSorter<BankAccountEntity>('alias'))
      .map(item => ({
      label: `${item.alias}`,
      value: item.id,
    }))
  }

  function getDateFormatOpts() {
    return [
      { label: 'Diário', value: 'd'},
      { label: 'Mensal', value: 'm'},
      { label: 'Anual', value: 'y'},
    ]
  }

  function getHelpDate() {
    let word = ''
    switch (form.getFieldValue('dateFormat')) {
      case 'm':
        word = 'meses';
        break;
      case 'y':
        word = 'anos';
        break;
      default:
        word = 'dias';
        break;
    }
    return `O sistema irá buscar 15 ${word} a partir da data informada`;
  }

  function setPicker(value: any) {
    switch (value) {
      case 'm':
        return setDatePickerFormat('month')
      case 'y':
        return setDatePickerFormat('year');
      default:
        return setDatePickerFormat('date');
    }
  }

  return (
    <>
      <HeaderCashFlow />
      <Divider />
      <Content>
        <Form form={form} onFinish={onSearch} layout={'vertical'} size={'middle'}>
          <Row gutter={12}>
            <Col span={8}>
              <Form.Item name={'firmId'} label={'Filial'}>
                <Select options={getFirmsOpts()} allowClear
                        // nChange={() => loadCostCenters()}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'costCentersCode'} label={'Centro de Custo'}>
                <Select options={getCostCenterOpts()} allowClear mode={'multiple'} />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name={'bankAccountId'} label={'Conta Bancária'}>
                <Select options={getBankAccountsOpts()}  allowClear />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <Form.Item name={'dateFormat'} label={'Formato de Retorno'} rules={[{ required: true }]}>
                <Radio.Group
                  ref={radioFormatDateRef.current}
                  onChange={e => setPicker(e.target.value)}
                  options={getDateFormatOpts()}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <DatePickerPtBr
                label={'Referência'}
                name={'startDate'}
                required={true}
                picker={datePickerFormat}
                help={getHelpDate()}
              />
            </Col>
          </Row>
          <Divider />
          <Row style={{display: 'flex', justifyContent: 'flex-end'}}>
            <Space>
              <Form.Item>
                <Button type={'primary'} icon={<SearchOutlined />} htmlType={'submit'} shape={'round'}>Pesquisar</Button>
              </Form.Item>
              <Form.Item>
                <Button type={'default'} icon={<ExceptionOutlined />} onClick={onExport} htmlType={'button'} shape={'round'}>Exportar</Button>
              </Form.Item>
              <Form.Item>
                <FilterManager
                  entity={'cash-flow'}
                  onSelected={data => {
                    form.setFieldsValue({
                      ...data,
                      startDate: moment(data.startDate)
                    })
                    if (data.dateFormat) {
                      console.log(data.dateFormat)
                      setPicker(data.dateFormat)
                    }
                  }}
                  getRawData={() => ({
                    ...form.getFieldsValue(),
                    startDate: form.getFieldValue('startDate').toDate().toISOString()
                  })}
                />
              </Form.Item>
            </Space>
          </Row>
        </Form>
        <ListCashFlowRowReport
          loading={cashFlowRepo.loading}
          data={data}
          dates={dates}
          overdueData={overdueRows}
          onCellClick={auditRow}
          dateFormat={dateFormat}
        />
      </Content>
      {
        auditData && (
          <Drawer
            placement={'bottom'}
            visible={true}
            onClose={() => setAuditData(undefined)}
            height={600}
          >
            <CashFlowAuditDateComponent {...auditData} dateFormat={dateFormat} />
          </Drawer>
        )
      }
    </>
  )
}
