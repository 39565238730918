import {Avatar, Badge, Button, Dropdown, List, Modal, Popover} from "antd";
import {
  BellOutlined,
  MediumWorkmarkOutlined,
  NotificationFilled,
  NotificationOutlined,
  NotificationTwoTone
} from "@ant-design/icons";
import React, {useEffect, useMemo, useState} from "react";
import {NotificationEntity} from "../../../domain/entity/notification.entity";
import {useNotificationRepository} from "../../../domain/repository/notification.repository";

export function NotificationButton(props: any) {
  const [notifications, setNotifications] = useState<NotificationEntity[]>([]);
  const [notificationModal, setNotificationModal] = useState<NotificationEntity>();

  const notificationRepo = useNotificationRepository();

  function loadNotifications() {
    return notificationRepo.listNotificationUser()
      .then(res => setNotifications([...res.data,...notifications]));
  }

  useEffect(() => {
    loadNotifications();
    const interval = setInterval(() => {
      return loadNotifications();
    }, 1000 * 60 * 10);

    return () => clearInterval(interval);
  }, [1])

  const totalNotifications = useMemo<number>(() => {
    return notifications.filter(w => !w.seen).length
  }, [notifications]);

  function setSeenNotifications() {
    const notSeen = notifications
      .filter(item => !item.seen)
      .map(item => item.id)
    if (!notSeen.length) {
      return;
    }
    return notificationRepo.setSeenNotifications(notSeen).then(() => {
      setNotifications(
        notifications.map(item => ({
          ...item,
          seen: true,
        }))
      )
    })
  }

  return (
    <>
      <Dropdown
        onVisibleChange={open => open ? setSeenNotifications() : null}
        overlay={() => (
          <NotificationList
            notifications={notifications}
            onClick={item => setNotificationModal(item)}
          />
        )}
        overlayStyle={{
          width: 250,
          backgroundColor: 'white',
          height: 'auto',
        }}
      >
        <Badge
          size={'small'}
          count={totalNotifications}
          offset={[-10,10]}
        >
          <Button
            type={'text'}
            icon={<BellOutlined />}
          />
        </Badge>
      </Dropdown>
      {
        notificationModal && (
          <Modal
            title={notificationModal.tittle}
            children={notificationModal.body}
            visible={true}
            footer={null}
            width={450}
            onCancel={() => setNotificationModal(undefined)}
          />
        )
      }
    </>
  )
}

function NotificationList(props: {
  notifications: NotificationEntity[],
  onClick: (notification: NotificationEntity) => any,
}) {
  return (
    <List
      size={'small'}
      dataSource={props.notifications}
      style={{
        maxHeight: 550,
        overflow: 'auto'
      }}
      renderItem={(item: NotificationEntity) => (
        <List.Item
          style={{
            cursor: 'pointer',
            backgroundColor: '#fafafa'
          }}
          onClick={() => props.onClick(item)}
        >
          <List.Item.Meta
            avatar={<Avatar size={'small'} icon={<NotificationOutlined size={10} style={{color: '#1b1b1b', fontSize: 12}}  />} />}
            title={`${item.id} - ${item.tittle}`}
            description={item.description}
            children={'adasd'}
          />
        </List.Item>
      )}
    />
  )
}